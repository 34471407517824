import { CSSProperties, FC } from 'react';
import ThemeUtils from '../../utils/ThemeUtils';

type LoaderProps = {
  size?: number;
  centered?: boolean;
  style?: CSSProperties;
};

const Loader: FC<LoaderProps> = (props) => {
  const { size = 32, centered = true, style } = props;
  const outerSize = ThemeUtils.getWidth(size);

  return (
    <span
      // we use a loader before tailwind loads, so style tag it is
      style={{
        display: 'inline-block',
        width: outerSize,
        height: outerSize,
        ...(centered ? { position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)' } : { position: 'relative' }),
        ...(style || {}),
      }}
      data-testid="loader-container"
      data-cy="loader"
      className="flex-shrink-0"
    >
      <svg id="loader" viewBox="0 0 200 200" fill="none" strokeWidth="28" strokeLinecap="round" xmlns="http://www.w3.org/2000/svg">
        <circle id="circleThree" stroke="#D77353" cx="50%" cy="50%" r="80" />
        <circle id="circleTwo" stroke="#614A59" cx="50%" cy="50%" r="80" />
        <circle id="circleOne" stroke="#3785A1" cx="50%" cy="50%" r="80" />
      </svg>
    </span>
  );
};

export default Loader;
