import { FC, lazy, StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import './assets/loader.css';
import PageLoader from './components/shared/page-loader/PageLoader';

const IndexWrapped = lazy(() => import('./indexWrapped'));

const Index: FC = () => {
  return (
    <StrictMode>
      <div style={{ width: '100%', minHeight: '100vh' }}>
        <Suspense fallback={<PageLoader loading />}>
          <IndexWrapped />
        </Suspense>
      </div>
    </StrictMode>
  );
};

const root = createRoot(document.getElementById('root')!);
root.render(<Index />);
