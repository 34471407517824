import { PropsWithChildren, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import Loader from '../Loader';
import { isInSuspenseAtom } from '../../../recoil/atoms/App';
import { FCWithChildren } from '../../../types/FCWithChildren';

type PageLoaderProps = {
  loading: boolean;
  isSuspense?: boolean;
  loaderSize?: number;
};

const PageLoader: FCWithChildren<PageLoaderProps> = (props) => {
  const { loading, isSuspense, loaderSize = 32, children }: PropsWithChildren<PageLoaderProps> = props;

  const setIsInSuspense = useSetRecoilState(isInSuspenseAtom);

  useEffect(() => {
    if (isSuspense) {
      setIsInSuspense(true);

      return () => {
        setIsInSuspense(false);
      };
    }
  }, [isSuspense, setIsInSuspense]);

  useEffect(() => {
    const html = document.querySelector('html') as HTMLElement;
    if (loading) {
      html.dataset.cy = 'contains-loader';
    } else {
      delete html.dataset.cy;
    }

    return () => {
      delete html.dataset.cy;
    };
  }, [loading]);

  if (loading) {
    return (
      <div
        // style because tailwind's css might not have loaded yet
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          zIndex: 20,
        }}
        data-cy="page-loader"
      >
        <Loader size={loaderSize} />
      </div>
    );
  }

  return <>{children}</>;
};

export default PageLoader;
